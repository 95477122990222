// https://nodlik.github.io/react-pageflip/
// import HTMLFlipBook from "react-pageflip";
// import { splitPages } from "../../utils/contentBreak";
// import PageView from "./PageView.jsx";
// import PageContent from "./PageContent.jsx";

const BookView = (props) => {

    // const width = window.innerWidth * 0.32;
    // const height = Math.min(window.innerHeight - 200, 700);

    const { content } = props;

    return (
        // <HTMLFlipBook width={width} height={height} autoSize={false}>
        //     {
        //         content && splitPages(content).map((page, indx) => (
        //             <PageView header={null} key={`page-item-${indx}`}>
        //                 <PageContent pageContentList={page} pageNum={indx} />
        //             </PageView>
        //         ))
        //     }
        // </HTMLFlipBook>
        <div className="page-container" dangerouslySetInnerHTML={{__html: content}}>
        </div>
    );
}
export default BookView;
