import { NavLink } from "react-router-dom";

const Anchor = (props) => {
    
    const {
        href, 
        style, 
        className,
        target,
        children
    } = props;

    const serverUrl = window.phpVariables?.serverUrl ?? window.phpVariables?.baseUrl ?? '';
    // const baseUrl = window.phpVariables?.baseUrl ?? '';

    const getReactHref = (link) => {
        return link ? link.replace(serverUrl, '') : link;
    }

    const loadUrl = (link) => {
        if(link) {
            link = `/loading/${window.btoa(link)}`
        }
        return link;
    }

    //${baseUrl}#
    return (
        <NavLink to={`${loadUrl(getReactHref(href))}`} 
            style={style} 
            className={className}
            target={target}
        >
            {children}
        </NavLink>
    );
}

export default Anchor;