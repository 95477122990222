import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../model/LoadingContext";

const HomeAbout = (props) => {
    
    const [aboutEn, setAboutEn] = useState(null);
    const [aboutHin, setAboutHin] = useState(null);
    const {updateLoading} = useContext(LoadingContext);

    useEffect(() => {
        const promises = []
        const {serverUrl} = window.phpVariables;
        updateLoading(true);
        promises.push(
            fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=about-english`)
            .then(data=>data.json())
        );
        promises.push(
            fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=about-hindi`)
            .then(data=>data.json())
        );
        Promise.all(promises).then((promiseData) => {
            const aboutEnDoc = new DOMParser().parseFromString(promiseData?.[0]?.[0]?.content?.rendered, "text/html")
            const aboutHinDoc = new DOMParser().parseFromString(promiseData?.[1]?.[0]?.content?.rendered, "text/html")
            let aboutEn = '';
            let aboutHin = '';
            for(let i = 0; i < 2; i++) {
                aboutEn += aboutEnDoc.querySelectorAll('p')[i].outerHTML;
                aboutHin += aboutHinDoc.querySelectorAll('p')[i].outerHTML;
            }
            aboutEn += `<a class="show-more-btn" href="#/about/en">Show More...</a>`;
            aboutHin += `<a class="show-more-btn" href="#/about/hin">और देखें...</a>`;
            setAboutEn(aboutEn);
            setAboutHin(aboutHin);
            updateLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section class="about-me-container">
            <div className="about-me-english"  dangerouslySetInnerHTML={{__html: aboutEn}}></div>
            <div className="about-me-hindi"  dangerouslySetInnerHTML={{__html: aboutHin}}></div>
        </section>
    );
}

export default HomeAbout;