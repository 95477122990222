import Menu from "./Menu.jsx"
import '../../styles/css/header.css'
// import Image from "../common/Image.jsx"
import SidebarContext from "../../model/SidebarContext.js"
import { useLocation } from "react-router"
import { useContext, useEffect } from "react"

const Header = (props) => {

    const location = useLocation();
    const {pathname} = location;
    const {isDark, updateIsDark} = useContext(SidebarContext);

    useEffect(()=>{
        updateIsDark(pathname === '/');
    }, [updateIsDark, pathname]);

    return (
        <header className={`header-top-menu ${isDark ? 'dark' : 'light'}`}>
            <div className="header-container">
                <div className="header-logo-container">
                    {/* <Image src={logoImage} /> */}
                </div>
                <Menu />
            </div>
        </header>
    );
}

export default Header;