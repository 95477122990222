import '../styles/css/home.css'
import LoadingContext from '../model/LoadingContext'
import { useContext, useEffect, useState } from 'react';
import HomeAbout from '../components/body/HomeAbout';

const Home = (props) => {

    const {children} = props;
        
    const [bgImageUrls, setBgImageUrls] = useState([]);
    const {updateLoading} = useContext(LoadingContext);

    useEffect(()=> {
        const promises = [];
        const {serverUrl} = window.phpVariables;
        updateLoading(true);
        promises.push(new Promise((resolve, _) => {
            fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=home&_embed`)
            .then(data=>data.json()).then(resolve);
        }))
        Promise.all(promises).then(([data, categories])=>{
            // TODO: Find images from here
            // let thumbnailUrls = data?.[0]?._embedded?.self?.[0]?.dfiFeatured?.split("{")[1]?.split("}")[0] ?? [];
            let thumbnailUrl = data?.[0]?._embedded?.['wp:featuredmedia']?.[0]?.['source_url']

            setBgImageUrls([thumbnailUrl]);
            updateLoading(false);
        }).catch((err)=>console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //bgImgUrls

    useEffect(()=>{
        if(bgImageUrls?.length > 1) {
            window.$('.home-bg-image').owlCarousel({
                items: 1,
                loop: true,
                autoplay: true,
                // autoWidth: true,
                dots: false,
                slideBy: 'page'
            });
        }
    }, [bgImageUrls]);

    return (
        <main className="home-body-container">
            <div className="home-bg-image">
                <div className="slides" 
                    style={{backgroundImage: `url('${bgImageUrls?.[0]}')`}}>
                    <HomeAbout />
                </div>
            </div>
            {children}
        </main>
    );
}

export default Home;