import BookView from '../components/body/BookView.jsx'
import LoadingContext from '../model/LoadingContext'
import '../styles/css/content.css'
import { Widget as Chat, addResponseMessage } from 'react-chat-widget';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import getBackgroundImage from '../components/common/BackgroundImage.js';

const Content = (props) => {

    const {children} = props;
    
    const {postName} = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const {updateLoading} = useContext(LoadingContext);
    // TODO: Get Author ID and Login
    const authorName = 'Guest'; // TODO: Use Context
    const [backgroundImage, setBackgroundImage] = useState('');
    const [postId, setPostId] = useState(0);
    const {serverUrl} = window.phpVariables;

    const fetchComments = ({postId, pageNumber, pageSize}) => {
        pageNumber = pageNumber || 1;
        pageSize = pageSize || 20;
        fetch(`${serverUrl}/wp-json/wp/v2/comments?post=${postId}&page=${pageNumber}&per_page=${pageSize}`)
            .then(data=>data.json())
            .then((commentData)=>{
                commentData?.forEach((commentItem) => {
                    addResponseMessage(`${commentItem?.author_name}: ${commentItem?.content}`);
                })
            })
            .catch(console.log)
    }

    // const encodeMessage = (msg) => window.encodeURIComponent(msg)

    const addComment = (newMessage) => {
        fetch(`${serverUrl}/wp-json/wp/v2/comments`, {
            method: 'POST',
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            // headers: {
            // 'Content-Type': 'application/json'
            // },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                post: postId,
                content: newMessage,
                author_name: authorName
            })
        }).then(data=>data.json())
        .then((commentData)=>{
            // addResponseMessage(`${authorName}: ${newMessage}`);
        })
    }

    useEffect(()=>{
        updateLoading(true);
        const promises = []
        promises.push(new Promise((resolve, reject) => {
            fetch(`${serverUrl}/wp-json/wp/v2/posts?slug=${postName}`)
            .then(data=>data.json())
            .then(resolve)
            .catch(console.log)
        }))
        Promise.all(promises).then(([postData])=>{
            // TODO: Get Category name here
            setBackgroundImage(getBackgroundImage('TODO'));
            setTitle(postData?.[0]?.title.rendered)
            let renderedContent = postData?.[0]?.content.rendered
            if(/class="pdfemb-viewer"/ig.test(renderedContent)) {
                const domContent = new DOMParser().parseFromString(renderedContent, "text/html")
                domContent.querySelectorAll('.pdfemb-viewer').forEach((pdfEmbedItem) => {
                    pdfEmbedItem.outerHTML = `<iframe class="pdfItem" src="${pdfEmbedItem.getAttribute('href')}" />`
                })
                renderedContent = new XMLSerializer().serializeToString(domContent)
            }
            setContent(renderedContent)
            setPostId(postData?.[0]?.id)
            fetchComments({postId: postData?.[0]?.id})
            updateLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postName])

    return (
        <main className="content-body-container" style={{backgroundImage: `url('${backgroundImage}')`}}>
            <div className="title-container"
                dangerouslySetInnerHTML={{__html: title}}
            ></div>
            {children}
            <div className="book-container">
                <BookView content={content}/>
            </div>
            <section className="comment-container">
                <Chat 
                    title="Comments"
                    subtitle=""
                    senderPlaceHolder="Add a comment"
                    handleNewUserMessage={addComment}
                />
            </section>
        </main>
    )
}
export default Content;