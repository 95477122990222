import './App.css';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './styles/css/base.css';
import Header from './components/header/Header.jsx';
import Home from './pages/Home.jsx';
import Content from './pages/Content.jsx';
import About from './pages/About.jsx';
import Gallery from './pages/Gallery.jsx';
import GalleryItems from './pages/GalleryItems.jsx';
import List from './pages/List.jsx';
import LoadingStack from './components/LoadingStack.jsx';
import VerticalList from './components/common/VerticalList.jsx';
import LoadingContext from './model/LoadingContext';
import SidebarContext from './model/SidebarContext';
import { useEffect, useState } from 'react';
import LoadingStackRoute from './pages/LoadingStackRoute';

function App() {

    const [loading, setLoading] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [isDark, setIsDark] = useState(true);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(()=>{
        const {serverUrl} = window.phpVariables;
        fetch(`${serverUrl}/wp-json/wp/v2/categories?search=special`)
        .then(data=>data.json())
        .then(category=>{
            console.log(category);
            fetch(`${serverUrl}/wp-json/wp/v2/categories?orderby=id&parent=${category[0]?.id}`)
            .then(data=>data.json())
            .then((categories)=>{
                setCategoryList(categories?.map((category)=> {
                    return {
                        'title': category.name?.split("|")[0],
                        'subtitle': category.name?.split("|")[1],
                        'link': category.link
                    }
                }))
            })
        })
    }, [])

    const sidebarContent = (isDark) => {
        return (
            <section className="category-sidebar">
                <VerticalList items={categoryList} isDark={isDark}/>
            </section>
        )
    }

    return (
        <div className="App">
            <LoadingContext.Provider value={{ loading, updateLoading: (val) => {
                console.log(val)
                setLoading(val)
            } }}>
                {loading && <LoadingStack />}
                <SidebarContext.Provider value={{
                    showSidebar, isDark, 
                    updateShowSidebar: (val)=>{setShowSidebar(val)},
                    updateIsDark: (val) => {setIsDark(val)}
                }}>
                    <Router basename="/">
                        <Header />
                        <Switch>
                            <Route path="/loading/:loadingPath">
                                <LoadingStackRoute />
                            </Route>
                            <Route path="/about/:lang">
                                <About>
                                    { showSidebar && sidebarContent(isDark) }
                                </About>
                            </Route>
                            <Route path="/about">
                                <About>
                                    { showSidebar && sidebarContent(isDark) }
                                </About>
                            </Route>
                            <Route path="/gallery/:categoryPageId">
                                <GalleryItems>
                                    { showSidebar && sidebarContent(isDark) }
                                </GalleryItems>
                            </Route>
                            <Route path="/gallery">
                                <Gallery>
                                    { showSidebar && sidebarContent(isDark) }
                                </Gallery>
                            </Route>
                            <Route path="/category/:category1?/:category2?">
                                <List>
                                    { showSidebar && sidebarContent(isDark) }
                                </List>
                            </Route>
                            <Route path="/:postName">
                                <Content>
                                    { showSidebar && sidebarContent(isDark) }
                                </Content>
                            </Route>
                            <Route path="/">
                                <Home>
                                    { showSidebar && sidebarContent(isDark) }
                                </Home>
                            </Route>
                        </Switch>
                    </Router>
                </SidebarContext.Provider>
            </LoadingContext.Provider>
        </div>
    );
}

export default App;
