import { useState } from "react";
import MenuItem from "./MenuItem.jsx";

const Menu = (props) => {

    const hindiStyles = {
        'fontFamily': `'Vesper Libre', serif`,
        'lineHeight': 1,
        'letterSpacing': 'normal',
        'fontSize': '119%'
    };

    const menuItems = [
        {
            name: 'Home',
            link: '/',
            children: []
        },
        {
            name: 'English Literature',
            children: [
                {
                    name: 'Original Poetry',
                    link: `/category/english/poetry`
                },
                {
                    name: 'Original Prose',
                    link: '/category/english/prose'
                }, 
                {
                    name: 'Translations',
                    link: '/category/english/translations'
                }
            ]
        },
        {
            name: 'हिंदी साहित्य',
            styles: hindiStyles,
            children: [
                {
                    name: 'मौलिक काव्य',
                    styles: hindiStyles,
                    link: '/category/hindi/poetry'
                },
                {
                    name: 'मौलिक गद्य',
                    styles: hindiStyles,
                    link: '/category/hindi/prose'
                },
                {
                    name: 'अनुवाद',
                    styles: hindiStyles,
                    link: '/category/hindi/translations'
                },
                {
                    name: 'अन्य',
                    styles: hindiStyles,
                    link: '/category/hindi/others'
                }
            ]
        },
        /*{
            name: 'Other Languages',
            children: [
                {
                    name: 'Original Poetry',
                    link: '/category/others/poetry'
                },
                {
                    name: 'Original Prose',
                    link: '/category/others/prose'
                }, 
                {
                    name: 'Translations',
                    link: '/category/others/translations'
                }
            ]
        },
        {
            name: 'Other Works',
            link: '/category/others'
        },*/
        {
            name: 'Gallery',
            link: '/gallery'
        },
        {
            name: 'About',
            link: '/about'
        }
    ];


    const [menuActive, setMenuActive] = useState(false);

    const setActive = () => {
        setMenuActive(!menuActive);
    }

    return (
        <nav className="header-nav-menu">
            <div className={`header-nav-menu-container ${!menuActive ? 'hamburger-hide' : ''}`}>
                {
                    menuItems.map((menuItem) => (
                        <MenuItem key={menuItem.name} item={menuItem}/>
                    ))
                }
            </div>
            <div className={`menu-hamburger${menuActive ? ' active' : ''}`} onClick={setActive}>
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
            </div>
        </nav>
    );
}

export default Menu;