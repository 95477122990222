import Anchor from "../common/Anchor";

const MenuItem = (props) => {

    const {name, link, children, styles} = props.item;

    const getIdifiedName = (val) => val.toLowerCase().replace(/[^A-Za-z]/ig, '-')

    const getLabel = () => link ? 
        (
            <Anchor href={link} style={styles}>{name}</Anchor>
        ) : (
            <span style={styles}>{name}</span>
        );

    return (
        <div className="header-menu-item">
            {
                children?.length ? (
                    <>
                        <label for={getIdifiedName(name)}>
                            {getLabel()}
                        </label>
                        <input type="checkbox" id={getIdifiedName(name)} name={getIdifiedName(name)} class="header-menu-child-opener" />
                        <div className="header-menu-item-children">
                            <div className="child-container">
                                {
                                    children.map((childMenuItem) => <MenuItem key={childMenuItem.name} item={childMenuItem} />)
                                }
                            </div>
                        </div>
                    </>
                ) : getLabel()
            }
        </div>
    );
}

export default MenuItem;