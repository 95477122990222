import '../styles/css/LoadingStack.css'; 

const LoadingStack = (props) => {

	return (
		<div className="loading-container stack">
		{/* <div className="book"></div>
		<div className="book"></div>  
		<div className="book"></div>
		<div className="book"></div>  
		<div className="book"></div>   */}
			<div className="lds-ring">
				<div/>
				<div/>
				<div/>
				<div/>
			</div>
		</div>
	);

}

export default LoadingStack;
