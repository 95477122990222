import { useEffect, useState, useContext } from "react";
import LoadingContext from '../model/LoadingContext';
// import Masonry from 'react-masonry-css';
import '../styles/css/gallery.css'
import GalleryItems from "./GalleryItems";
import getBackgroundImage from '../components/common/BackgroundImage.js';

const Gallery = (props) => {

    const {children} = props;
    const [galleryItems, setGalleryItems] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [backgroundImage, setBackgroundImage] = useState('');
    // const pageSize = 10;
    const {updateLoading} = useContext(LoadingContext);
    // const breakpointColumnsObj = {
    //     default: 4,
    //     1100: 3,
    //     700: 2,
    //     500: 1
    // };

    const loadMoreItems = () => {
        setPageNumber(pageNumber + 1);
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            loadMoreItems()
        }
    }

    useEffect(()=>{
        const {serverUrl} = window.phpVariables;
        updateLoading(true);
        let galleryCategory = [];
        fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=gallery`)
        .then(data=>data.json())
        .then((data)=> {
            galleryCategory = data?.[0]?.categories;
            fetch(`${serverUrl}/wp-json/wp/v2/pages?parent=${data?.[0]?.['id']}&_embed`)
        .then(data=>data.json())
        .then((data) => {
            setBackgroundImage(getBackgroundImage('gallery'));
            setGalleryItems(
                [...data.map((dataItem, indx) => {
                    return {
                        'image': dataItem?._embedded?.['wp:featuredmedia']?.[0]?.['source_url'] ?? `https://source.unsplash.com/random/${indx}`,
                        'title': dataItem.title.rendered,
                        'id': dataItem.id,
                        'categories': dataItem?.categories?.filter((item) => galleryCategory.indexOf(item) === -1)
                    }
                })]
            );
            updateLoading(false);
        });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    return (
        <div className="main-gallery-container" onScroll={handleScroll} style={{backgroundImage: `url(${backgroundImage})`}}>
            {children}
            <div className="gallery-container">
                {/* <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="grid-item"
                >
                    
                </Masonry> */}
                <div className="my-masonry-grid">
                    {
                        galleryItems?.map((galleryItem, indx) => (
                            // <Link className="grid-item" key={`gallery-category-${indx}`} to={`/gallery/${galleryItem.id}`}>
                            //     <div className="honeycomb-cell">
                            //         <img src={galleryItem.image} alt={galleryItem.title}/>
                            //         <div className="honeycomb-cell__title" dangerouslySetInnerHTML={{__html: galleryItem.title}} />
                            //     </div>
                            // </Link>
                            <div className="row-item" key={`gallery-category-${indx}`}>
                                <div className="grid-item">
                                    <div className="honeycomb-cell honeycomb-cell__title" dangerouslySetInnerHTML={{__html: galleryItem.title}} />
                                    <GalleryItems propCategoryPageId={galleryItem.id} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Gallery;