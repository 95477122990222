const getBackgroundImage = (category) => {
    if(category.indexOf('hindi::') > -1) {
        return 'https://bljha.com/wp/wp-content/uploads/2023/11/48019846.webp';
    } else if (category.indexOf('::ramkatha') > -1) {
        return 'https://bestplacesofindia.com/wp-content/uploads/2020/04/Kailash-and-Kotusmar-Cave-fetu.jpg';
    } else if (category.indexOf('others::') > -1) {
        return 'https://bestplacesofindia.com/wp-content/uploads/2020/04/Kailash-and-Kotusmar-Cave-fetu.jpg';
    } else {
        return 'https://bljha.com/wp/wp-content/uploads/2023/11/Chitrakot_waterfalls.jpg'
    }
}

export default getBackgroundImage;