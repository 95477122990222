import BookView from '../components/body/BookView.jsx'
import LoadingContext from '../model/LoadingContext'
import '../styles/css/content.css'
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

const About = (props) => {

    const {children} = props;
    const LANGUAGES = {
        'English': 'en',
        'हिंदी': 'hin'
    }
    const {postName, lang} = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [languageContent, setLanguageContent] = useState([]);
    const [activeLanguage, setActiveLanguage] = useState('en');
    const {updateLoading} = useContext(LoadingContext);

    const getRenderedContent = (postData) => {
        let renderedContent = postData?.[0]?.content.rendered
        if(/class="pdfemb-viewer"/ig.test(renderedContent)) {
            const domContent = new DOMParser().parseFromString(renderedContent, "text/html")
            domContent.querySelectorAll('.pdfemb-viewer').forEach((pdfEmbedItem) => {
                pdfEmbedItem.outerHTML = `<iframe class="pdfItem" src="${pdfEmbedItem.getAttribute('href')}" />`
            })
            renderedContent = new XMLSerializer().serializeToString(domContent)
        }
        return renderedContent;
    }

    useEffect(()=>{
        setActiveLanguage(lang);
        updateLoading(true);
        const {serverUrl} = window.phpVariables;
        const promises = []
        promises.push(new Promise((resolve, reject) => {
            fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=about-en`)
            .then(data=>data.json())
            .then(resolve)
            .catch(console.log)
        }))
        promises.push(new Promise((resolve, reject) => {
            fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=about-hin`)
            .then(data=>data.json())
            .then(resolve)
            .catch(console.log)
        }))
        Promise.all(promises).then(([aboutUsEnglish, aboutUsHindi])=>{
            setLanguageContent({
                'en': aboutUsEnglish,
                'hin': aboutUsHindi
            })
            console.log(lang)
            setActiveLanguage(lang);
            updateLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postName])

    useEffect(()=> {
        const postData = languageContent[activeLanguage]
        setTitle(postData?.[0]?.title.rendered)
        let renderedContent = getRenderedContent(postData)
        setContent(renderedContent)
    }, [activeLanguage, languageContent])

    return (
        <main className="content-body-container">
            {children}
            <select className="language-selection" value={activeLanguage} onChange={(event) => {setActiveLanguage(event.target.value)}}>
                {Object.keys(LANGUAGES).map(lang => (
                    <option  key={LANGUAGES[lang]} value={LANGUAGES[lang]}>{lang}</option>
                ))}
            </select>
            <div className="title-container"
                dangerouslySetInnerHTML={{__html: title}} />
            {children}
            <div className="book-container">
                <BookView content={content}/>
            </div>
        </main>
    )
}

export default About;