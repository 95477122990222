import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import LoadingContext from '../model/LoadingContext';
import '../styles/css/gallery.css';
import { SRLWrapper } from "simple-react-lightbox";

const GalleryItems = (props) => {

    const {children, propCategoryPageId} = props;
    const [galleryItems, setGalleryItems] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const {categoryPageId} = useParams();
    const pageSize = 10;
    const {updateLoading} = useContext(LoadingContext)

    const loadMoreItems = () => {
        setPageNumber(pageNumber + 1);
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            loadMoreItems()
        }
    }

    useEffect(()=>{
        const {serverUrl} = window.phpVariables;
        updateLoading(true);
        // fetch(`${serverUrl}/wp-json/wp/v2/pages?slug=gallery`)
        // .then(data=>data.json())
        // .then((data)=> {
        //     fetch(`${serverUrl}/wp-json/wp/v2/media?parent=${data[0]['id']}&per_page=${pageSize}&page=${pageNumber}`)
        //     .then(data=>data.json())
        //     .then((data) => {
        //         setGalleryItems(
        //             [...galleryItems, ...data.map((dataItem) => {
        //                 return {
        //                     'image': dataItem.guid.rendered,
        //                     'title': dataItem.title.rendered
        //                 }
        //             })]
        //         );
        //         updateLoading(false);
        //     });
        // })
        fetch(`${serverUrl}/wp-json/spa/featured/images?id=${propCategoryPageId || categoryPageId}&per_page=${pageSize}&page=${pageNumber}`)
        .then(data=>data.json())
        .then((data) => {
            setGalleryItems(
                [...galleryItems, ...data]
            );
            updateLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])

    return (
        <div className="sub-gallery-container sub-item honeycomb-cell" onScroll={handleScroll}>
            {children}
            <div className="gallery-container">
                {
                    galleryItems?.map((galleryItem, indx) => (
                        <div className="gallery-content-container" key={'gallery-item-' + indx}>
                            <SRLWrapper options={{thumbnails: {showThumbnails: false}, buttons: {showNextButton: false, showPrevButton: false, showAutoplayButton: false, showDownloadButton: false, showFullscreenButton: false}}}>
                                <a href={galleryItem.full}>
                                    <img className="gallery-thumbnail" src={galleryItem.thumb} alt={galleryItem.caption} />
                                </a>
                            </SRLWrapper>
                            
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default GalleryItems;