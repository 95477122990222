import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import LoadingStack from '../components/LoadingStack';
import '../styles/css/LoadingStack.css'; 

const LoadingStackRoute = (props) => {

	const {loadingPath} = useParams();
	const history = useHistory();

	useEffect(() => {
		if(loadingPath) {
			history.replace(window.atob(loadingPath));
		}
	}, [history, loadingPath])

	return (
		<LoadingStack />
	);

}

export default LoadingStackRoute;
