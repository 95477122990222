import { useEffect, useState } from 'react';
import Anchor from './Anchor.jsx';
import useWindowSize from './windowSize.js';

const VerticalList = (props) => {

    const {items, isDark} = props

    const [menuActive, setMenuActive] = useState(false);
    const size = useWindowSize();

    const setActive = () => {
        setMenuActive(!menuActive);
    }

    useEffect(()=> {
        if(size.width > 700) {
            setMenuActive(true);
        } else {
            setMenuActive(false);
        }
    }, [size])

    return (
        <div className="vertical-scroller-container">
            <div className="vertical-scroller-items" style={{display: menuActive ? 'flex' : 'none'}}>
                {items.map((item, indx)=>{
                    return (
                        <Anchor 
                            className={`vertical-scroller-item ${isDark ? 'dark' : ''}`} 
                            key={`${item.title}-${indx}`}
                            href={item.link}
                        >
                            <p style={{borderBottom: 'solid thin'}}>{item.title}</p>
                            <p>{item.subtitle}</p>
                        </Anchor>
                    )
                })}
            </div>
            <div className={`menu-hamburger${menuActive ? ' active' : ''}`} style={{position: 'fixed', bottom: '10px', left: '10px'}} onClick={setActive}>
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
                <div className="hamburger-icon"></div>
            </div>
        </div>
    );
}

export default VerticalList;