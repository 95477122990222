import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Anchor from '../components/common/Anchor.jsx';
import '../styles/css/list.css'
import LoadingContext from '../model/LoadingContext'
import getBackgroundImage from '../components/common/BackgroundImage.js';

const List = (props) => {

    const {children} = props;
    const pageSize = 10;
    const [listItems, setListItems] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const {category1, category2} = useParams();
    const {updateLoading} = useContext(LoadingContext)
    const [backgroundImage, setBackgroundImage] = useState('');

    const loadMorePages = () => {
        setPageNumber(pageNumber + 1);
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            loadMorePages()
        }
    }

    useEffect(()=>{
        const {serverUrl} = window.phpVariables;
        updateLoading(true);
        let searchString = '';
        if(category1) {
            searchString += category1;
        }
        if(searchString) {
            searchString += ","
        }
        if(category2) {
            searchString += category2;
        }
        if(/,$/.test(searchString)){
            searchString = searchString.substring(0, searchString.length - 1)
        }
        if(searchString) {
            searchString = "&category=" + searchString
        }
        searchString = `&per_page=${pageSize}&page=${pageNumber}` + searchString;
        let totalPostCount = 0;
        fetch(`${serverUrl}/wp-json/spa/category/search?type=post${searchString}`)
        .then(response=>{
            totalPostCount = parseInt(response.headers.get('x-wp-total') || "0");
            return response.json();
        })
        .then((postList) => {
            setShowLoadMore(postList?.length < totalPostCount);
            let finalListItems = postList?.map((post)=>{
                return {
                    name: post.title,
                    link: post.link
                };
            })
            if(pageNumber > 1) {
                finalListItems = [...listItems, ...finalListItems]
            } else {
                setBackgroundImage(getBackgroundImage(`${category1}::${category2}`));
            }
            setListItems(finalListItems)
            updateLoading(false)
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category1, category2, pageNumber])

    return (
        <div className="list-body-container" style={{backgroundImage: `url('${backgroundImage}')`}}>
            {children}
            <div className="book-shelf-container">
                <div className="book-shelf-books-container" onScroll={handleScroll}>
                {
                    listItems?.map((listItem, indx) => (
                        <Anchor className={`book-shelf-book-container ${category1} ${category2}`} href={listItem.link} key={'list-item-' + indx}>
                            <span dangerouslySetInnerHTML={{__html: listItem.name}}></span>
                        </Anchor>
                    ))
                }
                {
                    showLoadMore && (
                        <div className="book-shelf-book-container load-more-button" onClick={loadMorePages}>
                            More...
                        </div>
                    )
                }
                </div>
                <div className="book-shelf-base"></div>
            </div>
        </div>
    );
}

export default List;
